@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {

  html,
  body {
    font-family: "Ubuntu", sans-serif;
  }
}

*::-webkit-scrollbar-track {
  background: #EDF1F3;
  width: 100%;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background: #ACB5BB;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



input[type="text"]:focus~.input-text,
input[type="text"]:not(:placeholder-shown)~.input-text {
  @apply text-blue-500 transform -translate-y-10 -translate-x-2 scale-75;
}

* {
  @apply p-0 m-0;
  font-family: "Ubuntu", sans-serif;
}

/* .common-input {
  @apply border-2 !border-[#DCE4E8] placeholder:text-[#00000080] placeholder:font-semibold !outline-none !rounded-lg !text-[18px] !px-3 !py-1 h-[56px] w-full
} */
.common-textarea {
  @apply border-2 !border-[#DCE4E8] placeholder:text-[#00000080] placeholder:font-semibold outline-none rounded-lg resize-none text-[18px] p-3 w-full md:h-[256px]
}

.common-select {
  @apply border-2 !border-[#00000095] placeholder:text-[#00000080] placeholder:font-semibold !outline-none !rounded-md !text-[18px] p-3 !px-3 !py-1 w-full
}

/* new class created as i not want to affect previous styling*/

.secondary-select {
  @apply border-2 !border-[#DCE4E8] placeholder:text-[#00000080] placeholder:font-semibold outline-none rounded-lg resize-none text-[18px] p-2 h-[56px]
}

/* .common-input {
  @apply !border-2 !border-[#00000095] placeholder:text-[#00000080] placeholder:font-semibold !outline-none !rounded-md !text-[18px] !px-3 !py-1 
} */

.model-input {
  @apply placeholder:text-[black] border-black border-2 w-full py-2 px-4 text-lg outline-none rounded-2xl
}


@layer utilities {
  .min-h-vh-143 {
    min-height: calc(100vh - 143px);
  }

  @media (max-width: 500px) {
    .min-h-vh-143 {
      min-height: calc(100vh - 108px);
    }
  }
}





.layout-child {
  width: calc(100% - 200px);
  margin-top: 100px;
  height: 100%;
  margin-left: auto;
}

.special-label {
  display: none !important;
}

.common-input,
.common-textarea {
  border: 1px solid #DCE4E8;
  padding: 15px 20px;
  color: #1A1C1E;
  width: 100%;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  height: 55px;
}

.common-input::placeholder,
.common-textarea::placeholder {
  color: #74746E;
  font-size: 1rem;
  font-weight: 500;
}

.common-textarea {
  height: auto;
}

.common-input:active,
.common-input:focus {
  border: 1px solid #DCE4E8;
  outline: none;
  box-shadow: none;
  color: #1A1C1E;
  font-weight: 500;
}

.common-lable {
  position: absolute;
  top: -12px;
  left: 14px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #ACB5BB;
  background-color: #fff;
  padding: 5px;
  z-index: 1;
}

.common-checkbox {
  border: 1px solid #E3E3D8;
  accent-color: #03B4BF;
}

.special-text {
  color: #03B4BF;
}

.strike-through {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: #000;
}
/* header */
.add-buss-menu {
  box-shadow: 0px 4px 58.599998474121094px 0px #0000001A;
  border: 1px solid #E7E7DB;
  border-top: none;
  max-height: calc(100dvh - 40px);
  height: auto;
  position: absolute;
  top: 0;
  padding-top: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.sidebar-navs {
  height: calc(100dvh - 100px);
  overflow-y: scroll;
}

.sidebar-navs::-webkit-scrollbar {
  width: 5px;
}

.sidebar-navs::-webkit-scrollbar-track {
  background: transparent;
}


/* card status */
.card-status {
  width: 90px;
  height: 24px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 6px 12px;
  border-radius: 4px;
  font-family: "Ubuntu", sans-serif;
  min-width: 90px;
  background-color: #ECF8F0;
  color: #1C8C6E;
}

.card-status-list {
  display: flex;              
  justify-content: center;    
  align-items: center;        
  height: 24px;               
  min-width: 90px;           
  padding: 6px 12px;         
  border-radius: 4px;        
  font-family: "Ubuntu", sans-serif;
  background-color: #ECF8F0;  
  color: #1C8C6E;             
  text-align: center;         
}

.card-status.active,
.active {
  background-color: #ECF8F0;
  color: #1C8C6E;
}

.card-status.deleted,
.deleted {
  background-color: #edeae8;
  color: #605f5e;
}

.card-status.inactive,
.inactive {
  background-color: #FFE1DE;
  color: #DB3D3D;
}

.card-status.expired,
.expired {
  background-color: #DB3D3D;
  color: #f0bdbd;
}

.card-status.sheduled,
.sheduled {
  background-color: #DCF3FF;
  color: #3D81DB;
}

.card-status.admin,
.admin {
  background-color: #E8E51A;
  color: #2E2E05;
}

.pagination-cust li button {
  background-color: #F8F8F8 !important;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 8px;

}

.pagination-cust .Mui-selected {
  border: 1px solid #00000057;
}

.pagination-cust li:first-child button,
.pagination-cust li:last-child button {
  border: 1px solid #d0d1d4;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 8px;

}

button#pagination-next-page,
button#pagination-last-page,
button#pagination-previous-page,
button#pagination-first-page {
  border: 1px solid #d0d1d4;
  width: 32px;
  height: 32px;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.form-select {
  border: 1px solid #F1F2F4;
  border-radius: 1rem;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  height: 35px;
  padding: 10px;
  border-radius: 8px;
  color: #111827;
}

/* content search */
.contant-res-design-child {
  box-shadow: 0px 9px 10px 0px #0000000D;
}

.right-step-line {
  position: relative;
}

.right-step-line::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 4px;
  background-color: #E7E7DB;
  left: -8px;
}


/* content footer */
.content-footer {
  @apply w-full flex justify-end items-center mt-auto pt-3 pb-3 gap-2 bg-white sticky bottom-0 z-[2]
}

/* content edit */
.custom-calendar,
.custom-color,
.rmdp-top-class,
.rmdp-calendar {
  width: 100% !important;
  margin: 0 !important;
}

.rmdp-day-picker,
.rmdp-day-picker div {
  width: 100% !important;
}

.custom-color .rmdp-week-day {
  color: #B3B3A5;
}

.custom-calendar .rmdp-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-week {
  gap: 0 !important;
}

.custom-color .rmdp-day,
.custom-color.rmdp-week-day {
  width: 100% !important;
  height: 40px !important;
  padding: 15px;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 100%;
}

.custom-color .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #009CA6 !important;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-color .rmdp-day.rmdp-range span:not(.highlight) {
  background-color: #009CA6 !important;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rmdp-range {
  background-color: #009CA6 !important;
  box-shadow: none !important;
}

.rmdp-day.rmdp-today span {
  background-color: #fff !important;
  color: #000 !important;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #009CA6 !important;
  border-radius: 4px !important;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #989898 !important;
}

.rmdp-arrow-container {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #DDDDD7 !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;

}

.rmdp-arrow-container i {
  width: 10px !important;
  height: 10px !important;
  border-color: black !important;
}

.rmdp-arrow-container:hover {
  background-color: #009CA6 !important;
}

.rmdp-arrow-container:hover .rmdp-arrow-container i {
  border-color: #009CA6 !important;
}

.active-target-age {
  background-color: #009CA6;
  color: #D3E9EB;
  font-weight: 600;
}

.disabled-target-age {
  opacity: 0.5;
  cursor: default;
}

/* map preview */

.map-preview ul,
.date-preview ul {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  padding-bottom: 20px;
}

.map-preview-li {
  width: 70%;
  flex: 0 0 70%;
  text-align: center;
  overflow: hidden;
}

.date-preview ul li {
  width: 25%;
  flex: 0 0 25%;
  text-align: center;
  overflow: hidden;
}

.busi-price-card {
  position: relative;
}

.busi-price-card::before {
  position: absolute;
  content: '';
  background-image: url('./assets/img/LogoSign.png');
  width: 60%;
  height: 60%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.my-dahboard-right {
  width: calc(100% - 220px);
}

.busi-detail-menu a.active {
  background-color: #E3E3D8;
  font-weight: 600;
}

.cust-phone.react-tel-input .form-control {
  border-radius: 10px !important;
  border-color: #DCE4E8 !important;
}

.cust-phone.react-tel-input .flag-dropdown {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-color: #DCE4E8 !important;
}

.cust-phone.react-tel-input .selected-flag {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.pac-container {
  z-index: 999999999 !important;
}

.react-select__control {
  border: 1px solid #DCE4E8;
  padding: 8px 15px;
  color: #1A1C1E;
  width: 100%;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  /* height: 55px; */
}

.react-select__indicators {
  align-items: flex-start;
}

.clear-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

@media only screen and (max-width: 767.99px) {
  .layout-child {
    width: 100%;
  }

  .busi-detail-menu {
    border: 1px solid #DCE4E8;
    border-radius: 10px;
    flex-direction: column;

  }

  .business-price-card {
    width: 100%;
    overflow-y: scroll;
    flex-wrap: nowrap;
  }

  .business-price-card .business-price-card-item {
    width: 100% !important;
    flex: 0 0 80% !important;
  }

  /* .busi-detail-menu a{
    width: 60%;
    flex: 0 0 60%;
  } */

  .my-dahboard-right {
    width: 100%;
  }

  /* content search */
  .search-content {
    width: 100%;
    flex: 1 1 100%;
  }

  .contant-res-design {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    padding-bottom: 20px;
  }

  .contant-res-design-child {
    width: 80%;
    flex: 0 0 80%;
  }

  .sidebar-navs {
    height: calc(100dvh - 150px);
  }

  .profle-hide-menu {
    width: 100vw;
    max-width: calc(100% - 50px);
    padding-left: 2rem;
  }

  .profle-show-menu {
    width: 100vw;
    max-width: calc(100% - 110px);
    margin-left: 1.5rem;
  }

  .date-preview ul li {
    flex: 0 0 38%;
  }
}