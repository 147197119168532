.primary-loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  background: #00000070;
}

.primary-loader .centered {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}
.primary-loader .blob-1,
.primary-loader .blob-2 {
  width: 70px;
  height: 70px;
  position: absolute;
  background: #000;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.primary-loader .blob-1 {
  left: 20%;
  animation: osc-l 2.5s ease infinite;
}
.primary-loader .blob-2 {
  left: 80%;
  animation: osc-r 2.5s ease infinite;
  background: #03b4bf;
}
@keyframes osc-l {
  0% {
    left: 20%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 20%;
  }
}
@keyframes osc-r {
  0% {
    left: 80%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 80%;
  }
}

/*Secondary Loader*/

.secondary-loader {
  /* background: red; */
  /* width: 100%;
  height: 100%;
  overflow: hidden; */
}

.lds-ring {
  position: fixed;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 5px solid #03b4bf;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #03b4bf transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*  content loader  */

/* .content-loader {
  text-align: center;
  margin-top: 120px;
  margin-left: -98px;
  height: 35px;
} */

.content-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure it takes up the full height of the parent */
  width: 100%;  /* Ensure it takes up the full width of the parent */
}

.content-loader .loader {
  position: relative;
  width: 100px;
  height: 16px;
}
.content-loader .loader:before,
.content-loader .loader:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #03b4bf;
  box-shadow: 32px 0 #03b4bf;
  left: 0;
  top: 0;
  animation: ballMoveX 1s linear infinite;
}
.content-loader .loader:after {
  box-shadow: none;
  transform-origin: 40px 0;
  transform: rotate(-153deg);
  animation: rotateLoader 1s linear infinite;
}

@keyframes rotateLoader {
  0%,
  10% {
    transform: rotate(-153deg);
  }
  90%,
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ballMoveX {
  0%,
  10% {
    transform: translateX(0);
  }
  90%,
  100% {
    transform: translateX(32px);
  }
}
