.currency-symbol {
  position: absolute;
  /* left: 10px; */
  /*right: 72px;*/ 
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #1A1C1E;
  font-weight: 500;
}

.common-input-right {
  text-align: right !important;
}